<template>
  <div class="temp">
    <div class="d-flexjustify-center temp">
      <div>
        <h1>Access Denied</h1>
        <p>This application can only be used on the desktop</p>
      </div>
      <v-btn @click="print">cxxx</v-btn>
      <div v-if="ohCrap" >
        oh crap!
      <img :src="ohCrap" alt="" />
      </div>
    </div>
    <img
      class="img"
      id="lalapo"
    src="../../assets/img/Device not support0.png"
      alt=""
    />
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  name: "check",
  data() {
    return {
      ohCrap: null,
    };
  },
  methods: {
    print() {
      let vm = this
      let im = document.getElementById("lalapo");
      
      html2canvas(im).then(function (canvas) {
        canvas.id = "cap";
        let cnvs = canvas.toDataURL("image/png");

        var byteString = atob(cnvs.split(",")[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        let filename = `${now}_${userId}_${fullname}_intray_${route}_${h}_`;
        let newBlob = new File([ab], filename, { type: "image/jpeg" });
        vm.ohCrap = newBlob
        console.log(newBlob);
      });
    },
  },
};
</script>

<style scoped>
.temp {
  height: 300vh;
}
.img {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
}
.breakpoint {
  position: relative;
  background: burlywood;
}
</style>